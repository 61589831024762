<template>
  <div class="page flex-1 flexbox-vertical">
    <header class="header">
      <div class="container">
        <div class="column full">
          <h1>About</h1>
        </div>
      </div>
    </header>
    <div class="flexbox flex-1">
      <div class="flex-1 primary-background-colour-grad"></div>
      <div class="container no-padding block-on-mobile">
        <div class="flex-1 sidebar primary-background-colour-grad">
          <router-link to="/about">About Smileonthetiles</router-link>
          <!-- <router-link to="/about/alex">About Alex</router-link> -->
        </div>
        <main class="flex-3">
          <router-view></router-view>
        </main>
      </div>
      <div class="flex-1 white-background-colour"></div>
    </div>
    <site-footer/>
  </div>
</template>

<script>
  import SiteFooter from '../components/SiteFooter.vue'

  export default {
    name: 'about',
    components: { SiteFooter }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";
</style>
