<template>
  <div class="flex-1 container">
    <div class="column flex-2">
      <h2>Welcome to Smileonthetiles!</h2>
      <p>Smileonthetiles is an inspiring and legendary night-life photography presence that has captured Plymouth’s smiling faces and events since 2005.</p>
      <p>With a back catalogue of over 1 million photos from so many venues, these past and present clubs and bars have given host to the smiles that our talented photographers capture every weekend all over Plymouth’s vibrant clubland areas.</p>
      <p>Our in-house and roaming photographers ensure that any reveller is highly likely to bump into a Sott photographer on the dance floor, by the bar or even on the street whilst you move between clubs.</p>
      <p>In addition with all the photos being uploaded here onto this website, you can even have a print of your photos made on-site into a keyring for you to take home.</p>
      <p>Every week we publish a selection of our best photos in Plymouth's local press; The Herald. If you are posing loads, lookin’ dappa and take a great photo, with smiles and laughter, you could be in print in a Friday publication!</p>
    </div>
    <div class="column flex-1">
      <div class="timeline">
        <div v-for="event in timeline" class="event">
          <h4 v-html="event.title"></h4>
          <div v-html="event.body"></div>
        </div>
      </div>
    </div>
    <script type="application/ld+json">
			{
			  "@context": "http://schema.org",
			  "@type": "Organization",
			  "name": "Smileonthetiles",
			  "alternateName": "Sott",
			  "url": "https://smileonethetiles.co.uk",
			  "logo": "https://smileonethetiles.co.uk/images/icons/icon-512.png",
			  "address": {
          "@type": "PostalAddress",
          "streetAddress": "Grosvenor House, Mutley Plain Ln",
          "addressLocality": "Plymouth",
          "addressRegion": "Devon, UK",
          "postalCode": "PL4 7DS"
			  },
			  "email": "mailto:info@smileonthetiles.co.uk",
			  "telephone": "+441752260245",
			  "founder": {
          "@type": "Person",
          "name": "Alex Christaki"
			  },
			  "sameAs": [
			    "https://www.facebook.com/smileonthetilesplymouth/",
			    "https://twitter.com/Smileonthetiles",
			    "https://www.instagram.com/smileonthetiles/"
			  ]
			}
    </script>
  </div>
</template>

<script>
  export default {
    name: 'about-home',
    data() {
      return {
        timeline: [
          {
            title: 'October 2005',
            body: '<p>First accidental photo taken at <a href="/galleries/plymouth/clubs-and-pubs/the-barbican/view-2">View 2</a> on the Barbican</p>'
          },
          {
            title: 'November 2005',
            body: '<p>10,000 users</p>'
          },
          {
            title: 'December 2005',
            body: '<p>First page of photos in the Plymouth Herald</p>'
          },
          {
            title: '2010',
            body: '<p>Started selling keyrings in Union Rooms</p>'
          },
          {
            title: '2010-12',
            body: '<p>1 million photos taken</p>'
          },
          {
            title: '2017',
            body: '<p>New bespoke website launched</p>'
          },
          {
            title: '2018',
            body: '<p>1.5 million photos reached</p>'
          },
          {
            title: '2019',
            body: '<p>200 different venues, 25,000 albums and 2,000 nights covered</p>'
          },
          {
            title: '2020',
            body: '<p>Smileonthetiles takes its final photo</p>'
          },
          {
            title: '2023',
            body: '<p>Alex Christaki, creator of Smileonthetiles, passes away</p>'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";

  .timeline {
    margin-top: 2rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 4rem;

    position: relative;

    &::before {
      width: 0.25rem;

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      background-color: #D3D3D3;

      content: "";
    }

    .event {
      position: relative;

      h4 {
        padding-bottom: 1rem;
      }

      &::before {
        width: 1rem;
        height: 1rem;

        position: absolute;
        top: 50%;
        right: calc(100% + 2.875rem);

        background-color: #FFF;

        border: 0.5rem $primary-colour solid;

        transform: translateY(-50%) rotate(45deg);

        content: "";
      }

      + .event {
        margin-top: 5rem;
      }
    }
  }
</style>
