<template>
  <div class="flex-1 container">
    <div class="column flex-2">
      <h1>Alex Christaki</h1>
      <p>1973&ndash;2023</p>
      <p>Alex Christaki was the founder of Smileonthetiles.</p>
    </div>
    <div class="column flex-1">
      <p>...</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'about-alex'
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";
</style>
